<template>
    <b-row>
        <b-col lg="6">
            <cluji-card title="Modifier vos informations" class="mb-5"
                        subtitle="S'il y a une erreur dans vos nom, prénom, etc., adressez vous au bureau">
                <b-form @submit.prevent="submit">
                    <b-form-group>
                        <label v-b-tooltip.hover="'Nécessaire pour toute modification'" slot="label">
                            Mot de passe actuel
                            <font-awesome-icon icon="question-circle"/>
                        </label>
                        <b-form-input v-model="form.password" type="password" required autocomplete="current-password"/>
                    </b-form-group>
                    <b-form-group label="Email">
                        <b-form-input v-model="form.email" type="email"/>
                    </b-form-group>
                    <b-row>
                        <b-col xl="6">
                            <b-form-group label="Mot de passe">
                                <b-form-input v-model="form.newPassword" type="password" autocomplete="new-password"/>
                            </b-form-group>
                        </b-col>
                        <b-col xl="6">
                            <b-form-group label="Confirmation">
                                <b-form-input v-model="form.passwordConf" type="password" autocomplete="new-password"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="text-center">
                        <b-button type="submit" variant="success">Valider</b-button>
                    </div>
                </b-form>
            </cluji-card>
        </b-col>

        <b-col lg="6">
            <cluji-card class="text-center mb-5" :no-header="true">
                <uploadable-picture :illustrated="user.utilisateur" type="utilisateurs"
                                    v-model="uploadPicture" class="profile-pic"
                                    v-b-tooltip.hover="'Carrée, jpg ou png, maximum 1Mo'"/>
                <p><strong>{{ user.utilisateur.prenom }} {{ user.utilisateur.nom }}</strong></p>
                <p v-if="user.utilisateur.pseudo">Aussi appelé <em>{{ user.utilisateur.pseudo }}</em></p>
                <p v-if="roleInfos">
                    Dont le statut est
                    <strong class="ml-1">
                        <font-awesome-icon :icon="roleInfos.icon"/>
                        {{ roleInfos.name }}
                    </strong>
                </p>
                <p>
                    Inscrit depuis le {{ formatDate(user.utilisateur.dateInscription) }}<br/>
                    (Soit une ancienneté de {{ age }})
                </p>
                <b-button @click="showOwnCollectedItems" size="sm">Récompenses</b-button>
                <b-button v-if="isCluji()" @click="showDiscordAssociation" size="sm" class="discord-button">
                    Discord
                </b-button>
                <height-fade-transition>
                    <div v-if="uploadPicture || user.utilisateur.imageExtension">
                        <hr/>
                        <p>Photo de profil :</p>
                        <p>
                            <b-btn variant="success" v-if="uploadPicture"
                                   size="sm" @click="uploadProfilePic">
                                Enregistrer
                            </b-btn>
                            <b-btn variant="danger" v-if="user.utilisateur.imageExtension"
                                   size="sm" @click="deleteProfilePic">
                                Supprimer
                            </b-btn>
                        </p>
                    </div>
                </height-fade-transition>
            </cluji-card>
        </b-col>

        <b-col xl="10" v-if="isCluji() || isAncien()">
            <borrowable-table-search-quickview :borrowables="jdrs" type="jdrs" title="Vos jeux de rôle"/>
        </b-col>

        <b-col xl="10" v-if="isCluji() || isAncien()">
            <borrowable-table-search-quickview :borrowables="jeux" type="jeux" title="Vos jeux de plateau"/>
        </b-col>

        <b-col xl="10" v-if="isCluji() || isAncien()">
            <borrowable-table-search-quickview :borrowables="livres" type="livres" title="Vos livres"/>
        </b-col>

        <b-col lg="12">
            <b-card class="text-center mb-5">
                <b-btn variant="outline-danger" @click="deleteAccount"
                       v-b-tooltip.hover="'Cette action est irréversible'">
                    <font-awesome-icon icon="skull-crossbones"/>
                    Supprimer mon compte
                    <font-awesome-icon icon="skull-crossbones"/>
                </b-btn>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import {mapState} from 'vuex';

    import {formatDate}                        from '@/util/date';
    import {age, roleInfos}                    from '@/util/user';
    import {apiPath}                           from '@/util/http';
    import {refreshSession, isCluji, isAncien} from '@/util/auth';
    import alert                               from '@/util/alert';

    const DisplayCollectedItemsModal     = () => import('@/components/modals/collectable_item/DisplayCollectedItemsModal');
    const DiscordAssociationModal        = () => import('@/components/modals/DiscordAssociationModal');
    const MailPreferencesCard            = () => import('@/components/MailPreferencesCard');
    const UploadablePicture              = () => import('@/components/UploadablePicture');
    const HeightFadeTransition           = () => import('@/components/HeightFadeTransition');
    const BorrowableTableSearchQuickview = () => import('@/components/BorrowableTableSearchQuickview');
    const ClujiCard                      = () => import('@/components/ClujiCard');
    const CardTableSearch                = () => import('@/components/CardTableSearch');
    const PasswordConfirmModal           = () => import('@/components/modals/PasswordConfirmModal');

    export default {
        name: "Profile",
        components: {
            MailPreferencesCard,
            HeightFadeTransition,
            UploadablePicture,
            BorrowableTableSearchQuickview,
            ClujiCard,
            CardTableSearch
        },
        data: () => ({
            uploadablePictureKey: '__will_be_randomly_generated__',
            uploadPicture: null,
            form: {
                email: null,
                password: null,
                newPassword: null,
                passwordConf: null
            },
            jdrs: [],
            jeux: [],
            livres: []
        }),
        computed: {
            ...mapState({user: 'user'}),
            age() {
                return age(this.user.utilisateur).humanize();
            },
            roleInfos() {
                return roleInfos(this.user.utilisateur);
            }
        },
        methods: {
            isAncien,
            isCluji,
            formatDate,
            loadData() {
                if (isCluji()) {
                    alert.loading();
                    this.axios.get(apiPath('list_own_jeux_quickview'))
                        .then(response => this.jeux = response.data)
                        .catch(() => this.$toaster.error('Impossible de récupérer la liste des jeux de plateau'))
                        .then(() => this.axios.get(apiPath('list_own_livres_quickview')))
                        .then(response => this.livres = response.data)
                        .catch(() => this.$toaster.error('Impossible de récupérer la liste des livres'))
                        .then(() => this.axios.get(apiPath('list_own_jdrs_quickview')))
                        .then(response => this.jdrs = response.data)
                        .catch(() => this.$toaster.error('Impossible de récupérer la liste des jeux de rôle'))
                        .finally(alert.stopLoading);
                }
            },
            submit() {
                alert.loading();
                this.axios.post(apiPath('edit_own_infos'), {...this.form})
                    .then(() => this.$toaster.success('Informations modifiées avec succès'))
                    .then(refreshSession)
                    .then(() => {
                        // If the email has been changed, the user is going to be logged out, so we refresh
                        // the page to force the redirection to the login page
                        if (!this.user.utilisateur) window.location.reload();
                    })
                    .catch(err => this.$toaster.error(err.response.data.error || 'Impossible de modifier vos informations'))
                    .finally(alert.stopLoading);
            },
            deleteAccount() {
                this.$store.dispatch('modal/create', {
                    component: PasswordConfirmModal,
                    props: {
                        title: 'Suppression de votre compte',
                        callback: password => this.axios.post(apiPath('delete_own_account'), {password: password})
                            .then(() => this.$toaster.success('Compte supprimé avec succès'))
                            .then(refreshSession)
                            .then(() => this.$router.push({name: 'homepage'}))
                            .catch(err => this.$toaster.error(err.response.data.error || 'Impossible de supprimer votre compte'))
                    }
                });
            },
            uploadProfilePic() {
                alert.loading();
                let formData = new FormData();
                formData.append('picture', this.uploadPicture);
                this.axios
                    .post(
                        apiPath('new_picture_user'),
                        formData,
                        {headers: {'Content-Type': 'multipart/form-data'}}
                    )
                    .then(refreshSession)
                    .then(() => this.uploadPicture = null)
                    .then(() => this.$toaster.success('Photo de profil modifiée'))
                    .catch(() => this.$toaster.error('Impossible de modifier votre photo'))
                    .finally(alert.stopLoading);
            },
            deleteProfilePic() {
                this.axios.delete(apiPath('delete_picture_user'))
                    .then(refreshSession)
                    .then(() => this.uploadPicture = null)
                    .then(() => this.$toaster.success('Photo de profil réinitialisée'))
                    .catch(() => this.$toaster.error('Impossible de supprimer votre photo'));
            },
            showOwnCollectedItems() {
                this.$store.dispatch('modal/create', {component: DisplayCollectedItemsModal});
            },
            showDiscordAssociation() {
                this.$store.dispatch('modal/create', {component: DiscordAssociationModal});
            }
        },
        mounted() {
            this.form.email = this.user.utilisateur.email;
            this.loadData();
        }
    }
</script>

<style scoped>
    .profile-pic {
        filter: none;
    }

    .discord-button {
        background-color: #7289da;
        border-color: #7289da;
        color: white;
    }
</style>